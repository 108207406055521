<script>
const defaultColorPickerOptions = [
  '#1595CE',
  '#FA6A6A',
  '#9955ED',
  '#FEA24B',
  '#14CCA8',
  '#7BF27F',
  '#75C1DD',
  '#EA54C7',
  '#0048C7',
  '#F7DD19'
]

const getRandomString = () => {
  return Math.random().toString()
}

export default {
  name: 'ColorPicker',
  components: {
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    value: null,
    name: {
      type: String,
      default: getRandomString()
    },

    options: {
      type: Array,
      default: defaultColorPickerOptions
    },

    label: {
      type: String,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  computed: {
    hasValidation () {
      return Object.keys(this.validation).length
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template>
  <div
    class="color-picker"
    :class="{'has-value': value, 'has-floating-label': label, 'theme-dark': dark, 'has-validation': hasValidation, 'has-error': validation.$error}"
  >
    <label
      v-if="label"
      :for="name"
      class="form-label"
    >
      {{ label }}
    </label>
    <div class="picker-item-list">
      <div
        v-for="(radioOption, radioIndex) in options"
        :key="radioIndex"
        class="picker-item"
        :class="{'is-checked': radioOption === value}"
      >
        <input
          :style="'background-color: ' + radioOption"
          class="radio-option"
          :value="value"
          :name="name"
          type="radio"
          @input="updateValue(radioOption)"
        >
      </div>
    </div>
    <ValidationMessage :validation="validation" />
  </div>
</template>

<style lang="scss" scoped>
.color-picker {
  width: 100%;
  display: flex;
  padding: 8px 0;
  margin-top: 16px;
  flex-direction: column;
  justify-content: flex-start;

  &.has-floating-label .form-label {
    margin-bottom: 8px;
    transform: translateY(0);
    line-height: 1.2em;
  }

  .picker-item-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .picker-item {
      margin-top: 24px;

      .radio-option {
        opacity: .8;
        width: 24px;
        height: 24px;
        cursor: pointer;
        appearance: none;
        margin-right: 16px;
        border-radius: 16px;
        transition: all 300ms ease;
      }
    }
  }

  .picker-item {
    &:not(.is-checked) {
      &:hover .radio-option {
        transform: scale(1.2);
        opacity: 1;
      }
    }

    &.is-checked .radio-option {
      transform: scale(1.5);
      opacity: 1;
    }
  }

  .form-input-messages-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
</style>
